jQuery(document).ready(function($) {
  
    // Menu Overlay
    jQuery(document).on('click', '.js-menu--toggle', function(e) {
        e.preventDefault();
        jQuery('.c-navbar__mobile-wrapper').toggleClass('is-open');
    });

    // // Hide the menu when clicking outside of it
    // jQuery(document).on('click', function(e) {
    //     var $menuWrapper = jQuery('.c-navbar__mobile-wrapper');
    //     if (!$menuWrapper.is(e.target) && $menuWrapper.has(e.target).length === 0 && !$menuWrapper.hasClass('is-open')) {
    //         $menuWrapper.removeClass('is-open');
    //     }
    // });

    // Open next drilldown section
    jQuery(document).on('click', '.js-drilldown-open > a', function(e){
      e.preventDefault();
      jQuery(this).next('.c-navbar__mobile__sub-menu').addClass("is-open");
    });

    // Move back a level on drilldown menu
    jQuery(document).on('click', '.js-drilldown-back', function(e){
        e.preventDefault();
        jQuery(this).closest('.c-navbar__mobile__sub-menu').removeClass("is-open");
    });

    // Drilldown header fix
    var menuHeaderHeight = jQuery('.c-navbar__mobile-wrapper > .o-wrapper').outerHeight();
    jQuery('.c-navbar__mobile__sub-menu.is-open').css('top', menuHeaderHeight);

});


// jquery to check if element actually exists
if (!!jQuery('.js-hamburger').length) {
  document.querySelector('.js-hamburger').onclick = function(){
    jQuery(this).toggleClass('is-active');
  }
}


//Get active nav item based on URL and set .active class on it
jQuery(document).ready(function($) {
  // Get the current URL
  var currentUrl = window.location.href;

  // Loop through each anchor tag in the navigation menu
  $('.c-navbar__main ul li a').each(function() {
      var linkUrl = $(this).attr('href');
      
      // Compare the current URL with the anchor tag's href attribute
      if (currentUrl.indexOf(linkUrl) !== -1) {
          $(this).addClass('active'); // Add .active class if there's a match
      }
  });
});