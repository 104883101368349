jQuery(document).ready(function($) {
   
    // Toggle filter on/off responsively
    function toggleFilterSidebar(event) {
        if (jQuery(window).width() <= 600) {
            event.stopPropagation();
            jQuery('.filter-sidebar').toggle();
        }
    }
    jQuery('.responsive-filter').click(toggleFilterSidebar);
    jQuery(document).click(function(event) {
        if (jQuery(window).width() <= 600 && 
            !jQuery(event.target).closest('.filter-sidebar').length && 
            !jQuery(event.target).closest('.responsive-filter').length) {
                jQuery('.filter-sidebar').hide();
        }
    });
    jQuery('.filter-sidebar').click(function(event) {
        event.stopPropagation();
    });



    // Toggle the search on mobile on/off when the icon is clicked
    $('.header-search-toggle').click(function(event) {
        if (window.innerWidth <= 600) {
            event.stopPropagation();
            $('.header-search').toggle();
        }
    });
    // Hide the search on mobile when clicked outside of it
    $(document).click(function(event) {
        if (window.innerWidth <= 600) {
            if (!$(event.target).closest('.header-search').length && 
                !$(event.target).closest('.header-search-toggle').length) {
                    $('.header-search').hide();
            }
        }
    });


    // Toggle the mini cart when the toggle button is clicked
    $('.mini-cart-toggle').click(function(event) {
        event.stopPropagation();
        $('.woo-minicart').toggle();
    });
    // Hide the mini cart when clicking outside of it
    $(document).click(function(event) {
        if (!$(event.target).closest('.woo-minicart').length && 
            !$(event.target).closest('.mini-cart-toggle').length) {
                $('.woo-minicart').hide();
        }
    });
    $(document.body).on('added_to_cart removed_from_cart', function() {
        var $cart_count = $('.cart-count');
        $.get('/?wc-ajax=get_refreshed_fragments', function(data) {
            var count = data.fragments['.cart-count'];
            if (parseInt(count) > 0) {
                if ($cart_count.length) {
                    $cart_count.text(count);
                } else {
                    $('.cart-contents').append('<span class="cart-count">' + count + '</span>');
                }
            } else {
                $cart_count.remove();
            }
        });
    });

    // hide woocommerce message div again after 5 seconds
    setTimeout(function() {
        $('.product-single .woocommerce-message').hide();
    }, 5000); // 5000 milliseconds = 5 seconds
});