jQuery(window).on("load", function() {


    // Homepage Favourite Products
    if (!!jQuery('.js-slick--homepage-products').length) {
        // Full width slick logos
        jQuery('.js-slick--homepage-products').slick({
            adaptiveHeight: false,
            arrows: true,
            autoplay: false,
            infinite: false,
            autoplaySpeed: 3000,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

});
